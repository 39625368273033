import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { TownDto } from "../../models/api/districtsApiTypes"
import { CategoryDto } from "../../models/api/categoriesApiTypes"
import Select, { MultiValue, components, NoticeProps, GroupBase } from "react-select"

interface ShopsFilterProps {
  towns: TownDto[]
  categories: CategoryDto[]
  townsFilter: string[]
  setTownsFilter: (towns: string[]) => void
  categoriesFilter: string[]
  setCategoriesFilter: (categories: string[]) => void
}

export default function ShopsFilter({
  towns,
  categories,
  townsFilter,
  setTownsFilter,
  categoriesFilter,
  setCategoriesFilter,
}: Readonly<ShopsFilterProps>) {
  const [show, setShow] = useState(false)

  const [selectedTowns, setSelectedTowns] = useState<TownDto[]>([])
  const [selectedCategories, setSelectedCategories] = useState<CategoryDto[]>([])

  useEffect(() => {
    // Initialize towns filter
    if (towns.length > 0 && selectedTowns.length === 0) {
      setSelectedTowns(towns.filter((t) => townsFilter.includes(t.id)))
    }
    // Initialize categories filter
    if (categories.length > 0 && selectedCategories.length === 0) {
      setSelectedCategories(categories.filter((c) => categoriesFilter.includes(c.id)))
    }
  }, [towns, categories])

  const handleClose = () => {
    setShow(false)
    setSelectedTowns(towns.filter((t) => townsFilter.includes(t.id)))
    setSelectedCategories(categories.filter((c) => categoriesFilter.includes(c.id)))
  }

  const handleShow = () => setShow(true)

  // See handleCategoryCheck for documentation
  const handleTownCheck = (items: MultiValue<TownDto>) => {
    setSelectedTowns([...items])
  }

  const handleCategoryCheck = (items: MultiValue<CategoryDto>) => {
    setSelectedCategories([...items])
  }

  const saveFilters = () => {
    if (
      selectedTowns.length !== townsFilter.length ||
      selectedCategories.length !== categoriesFilter.length ||
      selectedTowns.map((st) => st.id).filter((stid) => townsFilter.includes(stid)).length !== selectedTowns.length ||
      selectedCategories.map((sc) => sc.id).filter((scid) => categoriesFilter.includes(scid)).length !==
        selectedCategories.length
    ) {
      setTownsFilter(selectedTowns.map((t) => t.id))
      setCategoriesFilter(selectedCategories.map((c) => c.id))
    }
    setShow(false)
  }

  const NoOptionsMessage: React.ComponentType<NoticeProps<any, true, GroupBase<any>>> = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Nessuna opzione</span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h3 className="mt-4">Filtra le attivit&agrave;</h3>
          <div className="row mb-4">
            <div className="col-12 mb-4 mt-2">
              <h5>Comune</h5>
              <Select
                options={towns}
                isMulti={true}
                isSearchable={true}
                name="towns"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Seleziona citt&agrave;"
                value={selectedTowns}
                onChange={(items) => handleTownCheck(items)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{ NoOptionsMessage }}
              />
            </div>
            <div className="col-12 mb-4 mt-2">
              <h5>Settore</h5>
              <Select
                options={categories}
                isMulti={true}
                isSearchable={true}
                name="categories"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Seleziona categorie"
                value={selectedCategories}
                onChange={(items) => handleCategoryCheck(items)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{ NoOptionsMessage }}
              />
            </div>
          </div>
          <div className="text-end mb-4">
            <button type="button" className="btn btn-green" onClick={saveFilters}>
              Conferma
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <button type="button" className="btn btn-green-outline" onClick={handleShow}>
        <img src={`${process.env.PUBLIC_URL}/img/icons/filter.svg`} alt="Filtra" /> Filtri
      </button>
    </>
  )
}
