import React, { createContext, ReactNode, useContext, useMemo, useState } from "react"

export type FilterState = {
  categories: string[]
  towns: string[]
  page: number
}

export interface FilterContextType {
  filters: FilterState
  setFilters: (filters: FilterState) => void
}

export const FilterContext = createContext<FilterContextType | undefined>(undefined)

export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<FilterState>({ categories: [], towns: [], page: 1 })

  const providerValue = useMemo(() => {
    return { filters, setFilters }
  }, [filters])

  return <FilterContext.Provider value={providerValue}>{children}</FilterContext.Provider>
}

export const useFilters = (): FilterContextType => {
  const context = useContext(FilterContext)
  if (!context) {
    throw new Error("useFilters must be used within a FilterProvider")
  }
  return context
}
